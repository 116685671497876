
import { Vue, Component } from "vue-property-decorator";
import { ObjectDataSource } from "@/data/Object/ObjecDatatSource";
import {ListDataSource} from "@/data/List/ListDataSource";
import {Filter} from "@/cms-services/filter";
import userModule from "@/store/modules/user";
import {required} from "@/cms-services/consts";

@Component
export default class Home extends Vue {
  dataSource: ObjectDataSource = new ObjectDataSource({
    id: +this.$route.params.id,
    config: {},
    className: "review",
  });

  get caption() {
    return this.dataSource.model?.caption;
  }

  get breadcrumbs() {
    return [
      {
        text: "Главная",
        to: "/",
        exact: true,
      },
      {
        text: "Отзывы",
        to: "/manage/reviews/",
        exact: true,
      },
      {
        text: this.dataSource.model?.caption,
        disabled: true,
      },
    ];
  }

  createItemModel: any = {
    reviewId: +this.$route.params.id,
    createUserId: userModule.profile.id,
    caption: "",
    description:""
  };

  createItemFields: any = [
    {
      editor: "enum",
      attrs: {
        label: "Пользователь",
        hideDetails: true,
        class: "py-0 my-0",
      },
      name: "userProfileId",
      config: {
        getItems: "getUserProfiles",
        labelKeyName: "fullName",
        valueKeyName: "id",
        type: "select",
      },
      validations: [required()],
    },
    {
      editor: "string",
      name: "caption",
      config: {},
      attrs: {
        type: "text",
        label: "Заголовок",
      },
      validations: [required()],
    },
    {
      editor: "memo",
      name: "description",
      config: {},
      attrs: {
        type: "text",
        label: "Отзыв",
      },
      validations: [required()],
    },
  ];

  actions: any = {
    getUserProfiles: () => this.userProfileDataSource.items,
  };

  userProfileDataSource: ListDataSource = new ListDataSource({
    className: "userProfile",
    config: {
      pageIndex: 1,
      pageSize: 3000,
    },
  });
  reviewAnswerDataSource: any = new ListDataSource({
    config: {
      pageIndex: 1,
      pageSize: 100,
      filter: JSON.stringify([
        new Filter("reviewId", +this.$route.params.id),
      ]),
    },
    className: "reviewAnswer",
  });

  loaded: boolean = false;

  async created() {
    await this.userProfileDataSource.get();
    this.loaded = true;
  }
}
